<template>
  <div>
    <v-row justify="center" class="bank-profile-container-row">
      <v-col cols="2" class="bank-profile-side-panel hidden-sm-and-down">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
      <v-col cols="11" md="7">
        <v-card flat>
          <v-card-title>
            <label class="primary--text px-5 font-weight-bold bank-page-title ma-auto">{{ $t(`label.affiliate`) }}</label>
          </v-card-title>
        </v-card>

        <v-card>
          <div class="py-4 text-center">
            <div class="d-inline-block pa-2 body-1">
              <label>{{ `${$t(`label.affiliateCode`)}: ` }}</label>
              <label class="text-right font-weight-bold">{{ affiliateDetail.referralCode }}</label>
            </div>
            <div class="d-inline-block pa-2 px-1 body-1">
              <label>{{ `${$t(`label.affiliateBalance`)}: ` }}</label>
              <label class="text-right font-weight-bold">{{ affiliateDetail.walletBalance | currency }}</label>
            </div>
            <div class="d-inline-block pa-2 px-1 body-1">
              <label>{{ `${$t(`label.affiliateLevel`)}: ` }}</label>
              <label class="text-right font-weight-bold">{{ $t(`affilateLevel.${affiliateDetail.affiliateTypeId}`) }}</label>
            </div>
            <div class="d-inline-block pa-2 px-1 body-2" v-if="affiliateDetail.affiliateTypeId != 2 && (affiliateLevelTypeLog == null || affiliateLevelTypeLog.approvalStatusId == 3)">
              <v-btn tile color="white" class="secondary affiliate-upgrade-button" width="auto" @click="openAffilaiteTncDialog">{{ $t('label.upgradeAffiliateType') }}</v-btn>
            </div>
            <div class="d-inline-block pa-2 px-1 body-2" v-if="affiliateDetail.affiliateTypeId != 2 && affiliateLevelTypeLog != null && affiliateLevelTypeLog.approvalStatusId == 1">
              <v-btn tile disabled color="white" class="secondary affiliate-upgrade-button secondary--text" width="auto" @click="upgradeAffilaite">
                {{ $t(`affiliateLogStatus.${affiliateLevelTypeLog.approvalStatusId}`) }}
              </v-btn>
            </div>
          </div>

          <v-divider></v-divider>
          <div class="py-4">
            <div class="tab-list-container">
              <div class="tab-list text-center">
                <template v-for="link in affiliateLink">
                  <div class="tab-list-item" :key="link.text_key" v-if="link.accessLevel.indexOf(affiliateDetail.affiliateTypeId) >= 0">
                    <v-btn height="auto" min-height="auto" class="text-center tab-list-item-button" exact-active-class="active" link href :to="{ name: link.routeName }">
                      <label class="body-2 font-weight-bold">{{ $t(`label.${link.text_key}`) }}</label>
                    </v-btn>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="py-4">
            <router-view></router-view>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <app-dialog :dialogShow="affiliateTncDialog.show" :max-width="1100" :title="affiliateTncDialog.title" :closeAction="affiliateTncDialog.dialogXButton">
      <v-container class="fill-height full-weight px-0">
        <div class="affilaitTncContainer" @scroll="onScroll">
          <div v-html="affiliateTncContent"></div>
        </div>
      </v-container>
      <div>
        <v-row align="start" justify="center">
          <v-col cols="12" class="py-0">
            <v-checkbox
              class="my-0"
              hide-details
              :readonly="!affiliateTncDialogScrolledBottom"
              :disabled="!affiliateTncDialogScrolledBottom"
              :indeterminate="!affiliateTncDialogScrolledBottom"
              v-model="affiliateTncChecked"
              :label="$t('label.agree')"
            />
          </v-col>
        </v-row>
      </div>
      <app-button :disabled="!affiliateTncChecked" :action="upgradeAffilaite" :title="$t(`label.submit`)"></app-button>
    </app-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { AFFILIATE_DETAIL, AFFILIATE_UPGRADE_TYPE, AFFILIATE_RESET_UPGRADE_TYPE_STORE, AFFILIATE_LEVEL_TYPE_LOG } from '@/store/affiliate.module'
import { SHARED } from '@/constants/constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import AppBankAndProfileSidePanel from '@/components/layout/BankAndProfileSideMenu.vue'
export default {
  name: 'affilateLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.affiliate_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.affiliate_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.affiliate_keyword')}` }]
  },
  components: {
    AppBankAndProfileSidePanel
  },
  data: () => ({
    affiliateTncContent: null,
    affiliateLink: SHARED.PROFILE_AFFILIATE_LINK,
    affiliateTncDialog: {
      show: false,
      title: locale.getMessage(`label.affilaiteProgram`),
      dialogXButton: function() {}
    },
    affiliateTncDialogScrolledBottom: false,
    affiliateTncChecked: false
  }),
  computed: {
    affiliateDetail() {
      return this.$store.state.affiliate.detail
    },
    upgradeAffiliateTypeResponseComplete() {
      return this.$store.state.affiliate.upgradeAffiliateTypeResponse.complete
    },
    affiliateLevelTypeLog() {
      return this.$store.state.affiliate.latestLevelTypeLog
    }
  },
  watch: {
    upgradeAffiliateTypeResponseComplete() {
      let response = this.$store.state.affiliate.upgradeAffiliateTypeResponse
      if (response.complete) {
        this.upgradeAffiliateTypeResponseCompleteDialog(response)
        this.$store.dispatch(`${AFFILIATE_RESET_UPGRADE_TYPE_STORE}`)
      }
    },
    affiliateDetail() {
      if (this.affiliateDetail.affiliateTypeId == 1) {
        this.getAffilaiteLevelTypeLog()
      }
    }
  },
  created() {
    this.getAffiliateDetail(true)
    setInterval(this.getAffiliateDetail, 10000)
  },
  methods: {
    loadAffilaiteTncHtml() {
      let htmlPath = `${process.env.BASE_URL}static/html/other/${uiHelper.getLanguage()}/affiliate-tnc.html`
      axios({
        method: 'get',
        url: htmlPath
      }).then(result => {
        this.affiliateTncContent = result.data
      })
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        this.affiliateTncDialogScrolledBottom = true
      }
    },
    openAffilaiteTncDialog() {
      this.loadAffilaiteTncHtml()
      this.affiliateTncDialog.show = true
      this.affiliateTncDialog.dialogXButton = this.closeAffilaiteTncDialog
    },
    closeAffilaiteTncDialog() {
      this.affiliateTncDialog.show = false
    },
    getAffilaiteLevelTypeLog() {
      this.$store.dispatch(`${AFFILIATE_LEVEL_TYPE_LOG}`)
    },
    upgradeAffilaite() {
      this.closeAffilaiteTncDialog()
      this.$store.dispatch(`${AFFILIATE_UPGRADE_TYPE}`)
    },
    getAffiliateDetail(requiredRefresh = false) {
      this.$store.dispatch(`${AFFILIATE_DETAIL}`, { requiredRefresh })
    },
    upgradeAffiliateTypeResponseCompleteDialog(response) {
      let dialog = this.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.resquestUpgradeAffiliateTypeSuccessful`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.closePageDialog
        })
        this.getAffiliateDetail()
      }

      this.$parent.openPageDialog(dialog)
    }
  }
}
</script>

<style lang="scss">
.bank-profile-container-row {
  margin: 0 !important;
}
.tab-list-container {
  .tab-list-item {
    display: inline-block;
    margin: 10px;
    .tab-list-item-button {
      padding: 12px;
      background-image: linear-gradient(to left, var(--v-base_3-base), var(--v-base_3-base));
      letter-spacing: 0;
      min-width: 120px !important;
      &.active {
        background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
        color: white;
      }
    }
  }
}

.profile-tab-list-container {
  padding-bottom: 20px;
  padding-top: 20px;
}
.profile-tab-list-item {
  display: inline-block;
  padding: 0 10px;
  .profile-tab-list-item-button {
    background-image: linear-gradient(to left, var(--v-base_3-base), var(--v-base_3-base));
    letter-spacing: 0;
    min-width: 150px !important;
    &.active {
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
    }
  }
}

.bank-navigation-button {
  letter-spacing: 0;
  border-radius: 0px;
  padding: 24px !important;
  &.v-btn--active {
    span {
      font-weight: bold;
      color: var(--v-navigator_active-base);
    }
  }
  span {
    display: inline-block;
  }
}

.bank-page-title {
  border-bottom: 4px solid var(--v-title_color-base);
}

.bank-transaction-stepper {
  box-shadow: none;
  min-height: 350px;
}

@media (max-width: 1500px) {
  .bank-profile-side-panel {
    min-width: 250px;
  }
}
@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .bank-page-title {
    font-size: 16px;
  }

  /*.bank-navigation-button {
    padding: 10px 20px !important;
    min-height: auto;
    height: auto;
  }*/
}

.affiliateTnc-iframe {
  width: 100%;
  height: 500px;
}

.affilaitTncContainer {
  height: 80%;
  max-height: 500px;
  overflow-y: auto;
}
</style>
